import { render, staticRenderFns } from "./pickgoods.vue?vue&type=template&id=0323000a&scoped=true&"
import script from "./pickgoods.vue?vue&type=script&lang=js&"
export * from "./pickgoods.vue?vue&type=script&lang=js&"
import style0 from "./pickgoods.vue?vue&type=style&index=0&id=0323000a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0323000a",
  null
  
)

export default component.exports