<template>
  <div class="pickgoods">
    <van-tabs
      class="tabs_tab"
      title-active-color="#07C160"
      v-model="active"
      @change="tabTwo"
    >
      <van-tab title="申请提货">
        <div class="imgs">
          <img class="img_info" :src="img_url" alt />
        </div>
        <div class="kefu" style="color:red">*快递发货后需要1~2天送达，请合理安排发货日期</div>
        <van-form @submit="onSubmit">
          <van-field
            v-model="where.goods_name"
            name="goods_name"
            label="提货卡型号"
            placeholder="提货卡型号"
            disabled
          />
          <van-field
            v-model="where.card_no"
            ref="inputVal"
            @blur="cardBlur"
            name="card_no"
            label="提货卡号"
            placeholder="请输入提货卡号"
            :rules="[{ required: true, message: '请填写提货卡号' }]"
          />
          <van-field
            v-model="where.password"
            type="password"
            name="password"
            label="提货密码"
            placeholder="请输入提货密码"
            :rules="[{ required: true, message: '请填写提货密码' }]"
          />
          <van-field
            v-model="where.goods_title"
            label="选择商品"
            right-icon="arrow"
            name="goods_title"
            placeholder="选择提货商品"
            @click="chooseGoods()"
            readonly
          />

          <van-field
            v-model="where.username"
            name="username"
            label="姓名"
            placeholder="请填写姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
          />
          <van-field
            v-model="where.phone"
            name="phone"
            label="电话"
            type="number"
            placeholder="请填写电话"
            :rules="[{ required: true, message: '请填写电话' }]"
          />
          <!-- 收货地址 -->
          <van-field
            readonly
            clickable
            name="area"
            :value="value"
            label="选择收货地址"
            placeholder="点击选择选择收货地址"
            @click="showArea = true"
          />
          <van-popup v-model="showArea" position="bottom">
            <van-area
              :area-list="areaList"
              @confirm="onConfirm"
              @cancel="showArea = false"
            />
          </van-popup>
          <van-field
            v-model="where.user_detail"
            name="user_detail"
            label="详细地址"
            placeholder="请填详细地址"
          />
          <!-- 日期 -->
          <!-- <van-cell title="选择单个日期" :value="date" @click="show = true" /> -->

          <van-field
            readonly
            clickable
            name="yue_time"
            :value="valueDate"
            title="发货日期"
            label="发货日期"
            placeholder="点击选择日期"
            @click="showCalendar = true"
          />
          <div class="tips">
            *售后须知：如有售后问题，请将产品放快递单旁边一起拍视频,鲜活水产（蟹类、鱼类等）签收4小时内、其他产品当天24点之前发客服反馈，超时无法处理，望理解！
          </div>
          <!-- <van-calendar v-model="show" @confirm="onConfirm" /> -->
          <van-calendar
            :min-date="minDate"
            :max-date="maxDate"
            color="#07C160"
            v-model="showCalendar"
            :formatter="formatter" 
            @confirm="onConfirmDate"
          />
          <div style="margin: 30px 30px 20px 30px;">
          <van-field
            v-model="where.goods_id"
            type="hidden"
            name="goods_id"
          />
            <van-button
              round
              block
              type="info"
              :loading="loading"
              style="background: #07C160;
                     border-radius: 4px;
                     border:none"
              native-type="submit"
              >确认提交</van-button
            >
          </div>
          <div class="kefu">
            <div>*发货时间为发货日期18:00，请合理安排或咨询客服</div>
            客服电话:<a href="tel:15372094833">15372094833(24小时)</a><br>
            客服电话:<a href="tel:057188771967">0571-88771967(工作日9:00-18:00)</a>
          </div>
        </van-form>
      </van-tab>
      <div style="height:50px"></div>
      <van-tab title="查询进度">
        <div v-if="tabShow">
          <div class="imgs">
            <img class="img_info" :src="img_url" alt />
          </div>
          <van-form @submit="onSearch">
            <van-field
              v-model="where.goods_name"
              name="goodsrname"
              disabled
              label="商品名称"
              placeholder="商品名称"
            />
            <van-field
              v-model="where.card_no"
              name="card_no"
              label="提货卡号"
              @blur="cardBlurSearch"
              placeholder="请输入提货卡号"
              :rules="[{ required: true, message: '请填写提货卡号' }]"
            />
            <div style="margin: 30px;">
              <van-button
                round
                block
                type="info"
                style="background: #07C160;
                     border-radius: 4px;
                     border:none"
                native-type="submit"
                >点击查询</van-button
              >
            </div>
          </van-form>
        </div>
        <div style="margin-top:15px" v-else>
          <van-cell title="提货卡型号" value v-model="order.goods_name" />
          <van-cell title="商品名称" value v-model="order.goods_title" />
          <van-cell title="商品状态" value v-model="order.status" />
          <van-cell title="发货日期" value v-model="order.yue_time" />
          <van-cell
            value="详细信息"
            is-link
            v-model="logistics_status"
            @click="express"
          >
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="custom-title">物流信息</span>
            </template>
          </van-cell>
        </div>
      </van-tab>
      <van-tab title="卡充值">
        <div>
          <div class="btn_recharge" @click="goRecharge">去充值</div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import img_banner from "../../../static/imgs/banner2.jpg";
import {
  checkCard,
  createGiftOrder,
  giftDetail,
  getOpenId,
  giftTime,
  h5ToRoutierScheme
} from "@/api/hexiao";
export default {
  data() {
    return {
      active: 0,
      where:{
        card_no: "",
        password: "",
        card_type:0,
        username: localStorage.getItem('username') || "",
        phone: localStorage.getItem('phone') || "",
        user_detail: localStorage.getItem('user_detail')||"",
        goods_name: "",
        goods_title: "",
        yue_time: "",
        goods_id:0
      },
      minDate: new Date(),
      maxDate: new Date(2023, 12, 31),
      //收货地址
      value: localStorage.getItem("addressVal")||"",
      areaList,
      valueDate: "",
      showArea: false,
      showCalendar: false,
      imgCode: "",
      //两个遮罩层
      tabShow: true,
      order:{
        goods_name:'',
        goods_title:'',
        yue_time:'',
        order_sn:'',
        status:''
      },
      code: "",
      openId: "",
      status: "",
      img_url: img_banner,
      logistics_status: "",
      
      //物流单号
      order_sn: "",
      // 提交按钮加载状态
      loading: false,
    };
  },
  activated() {
     this.where.goods_title = localStorage.getItem('goodstitle')||'',
     this.where.goods_id = localStorage.getItem('goodsId') || 0
     this.checkGoodsTime()
     
  },
  mounted() {
    this.$nextTick(()=>{
      this.$refs.inputVal.focus();
    })
  },
  methods: {
    formatter(day){
      const cDay = day.date.getDate();
      const nowDay = new Date().getDate();
      const btnType = day.type;
      if(cDay>=nowDay && btnType =='disabled'){
        day.bottomInfo = '满';
      }
      return day;
    },
    //选择商品
    chooseGoods(){
      if(this.where.card_type == 0 || !this.where.card_no) return this.$toast.fail('请先输入卡号');
      this.$router.push({path:'/product',query:{'type':this.where.card_type}})
    },
    checkGoodsTime(){
      if(this.where.goods_id){
        giftTime(this.where.goods_id).then(res=>{
          
          let mindate = res.data.begin_time;
          let maxdate = res.data.end_time;
          this.minDate = new Date(Date.parse(mindate.replace(/-/g,  "/")));
          this.maxDate = new Date(Date.parse(maxdate.replace(/-/g,  "/")));
        }).catch(()=>{
           this.mindate =  new Date()
            this.maxDate = new Date(2023, 12, 31)
        })
      }
    },
    //提交订单
    onSubmit(values) {
      let that = this;
      let user_province = values.area.split("/")[0];
      let user_city = values.area.split("/")[1];
      let user_district = values.area.split("/")[2];
      values.user_province = user_province;
      values.user_city = user_city;
      values.user_district = user_district;
      values.card_type = this.where.card_type;
      that.loading = true;
      if (/MicroMessenger/.test(window.navigator.userAgent)) {
        let payType = "weixin";
        values.openId = that.openId;
        values.payType = payType;
      } else {
        let payType = "h5";
        values.payType = payType;
      }
      createGiftOrder(values)
        .then((res) => {
          that.loading = false;
          if (res.data.status == "STEP") {
            that.$dialog
              .confirm({
                title: res.msg,
              })
              .then(() => {
                //  支付
                let step = 0;
                values.step = step;
                that.onSubmit(values);
              })
              .catch(() => {
                // 不支付
              });
          }
          // 调起支付
          else if (res.data.status == "PAY_INFO") {
            if (values.payType == "weixin") {
              that.onBridgeReady(res.data.result);
            } else {
              this.$router.push({
                path: "/success",
                query: {
                  orderId: res.data.result.order_sn,
                  mweb_url: res.data.result.mweb_url,
                },
              });
            }
          } else if (res.data.status == "SUCCESS") {
            that.$toast.success(res.msg);
            that.where.password = "";
            that.where.goods_id = 0;
            that.where.goods_title = '';
            that.loading = false;
          }
          localStorage.setItem("username", that.where.username);
          localStorage.setItem("phone", that.where.phone);
          localStorage.setItem("user_detail", that.where.user_detail);
        })
        .catch((err) => {
          that.$toast.fail(err);
          that.loading = false;
        });
    },




   
    getCode() {
      var code = this.getUrlParam("code");
      var local = encodeURIComponent(window.location.href);
      var appid = "wx5de48df6425bb471";
      if (!this.openId) {
        if (code == null || code == "") {
          window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            appid +
            "&redirect_uri=" +
            local +
            "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
        } else {
          this.code = code;
          getOpenId({ code: this.code })
            .then((res) => {
              this.openId = res.data.openId;
            })
            .catch((err) => {
              return this.$toast(err);
            });
        }
      }
    },
    getUrlParam(name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    //微信支付
    onBridgeReady(data) {
      if (typeof WeixinJSBridge === "undefined") {
        return this.$toast({ message: "请使用微信内置浏览器支付" });
      } else {
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: data.appid,
            timeStamp: data.timeStamp,
            nonceStr: data.nonce_str,
            package: "prepay_id=" + data.prepay_id,
            signType: "MD5",
            paySign: data.paySign,
          },
          (res) => {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              this.$toast({ message: "支付成功" });

              this.$router.push({
                path: "/success",
                query: {
                  orderId: data.order_sn,
                },
              });
            } else {
              this.$toast({ message: "支付失败" });
            }
          }
        );
      }
    },
    //去物流信息
    express() {
      this.$router.push({
        path: "/network",
        query: {
          order_sn: this.order.order_sn,
        },
      });
    },
    //查询订单进度
    onSearch(values) {
      giftDetail(values)
        .then((res) => {
          this.tabShow = false;
          this.order.goods_name = res.data.goods_name;
          this.order.goods_title = res.data.goods_title;
          this.order.yue_time = res.data.yue_time;
          this.order.order_sn = res.data.order_sn;
          if (res.data.status == "1") {
            this.order.status = "待发货";
          }
          if (res.data.status == "2") {
            this.order.status = "已发货";
          }
          if (res.data.status == "3") {
            this.order.status = "已收货";
          }
        })
        .catch((err) => {
          this.$toast.fail(err);
        });
    },
    //tab切换
    tabTwo(e) {
      this.tabShow = true;

    },
    //卡号切换blur
    cardBlur() {
      if (this.where.card_no) {
        checkCard(this.where.card_no)
          .then((res) => {
            this.where.goods_name = res.data.goods_name;
            this.where.card_type = res.data.type;
          })
          .catch((err) => {
            this.$toast.fail(err);
            this.where.goods_name = "";
            this.where.card_type = 0;
          });
      }
    },
    //查询blur
    cardBlurSearch() {
      checkCard(this.where.card_no)
        .then((res) => {
          this.where.goods_name = res.data.goods_name;
        })
        .catch((err) => {
          this.$toast.fail(err);
          this.where.goods_name = "";
        });
    },
    //选择收货地址
    onConfirm(values) {      
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      localStorage.setItem("addressVal", this.value);
      this.showArea = false;
    },
    //日期选择
    onConfirmDate(date) {
      this.valueDate = `${date.getFullYear()}-${date.getMonth() +
        1}-${date.getDate()}`;
      this.showCalendar = false;
    },
    //去小程序
    goRecharge(){
       h5ToRoutierScheme().then(res=>{
           window.location.href =res.data.openLink;
       }).catch(err=>{
          this.$toast({ message:err });
       })
    }
  },
};
</script>

<style lang="less" scoped>
.pickgoods {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ededed;
  /deep/.tabs_tab {
    height: 40px;
    line-height: 40px;
  }
  .imgs {
    width: 88%;
    height: 160px;
    background: #d8d8d8;
    border-radius: 15px;
    margin: 20px auto;
  }
  .code {
    display: flex;
    .code_img {
      width: 150px;
      height: 50px;
      padding: 2px 5px 0 5px;
      img {
        align-items: center;
        vertical-align: middle;
        height: 100%;
        width: 100%;
      }
    }
  }
  /deep/.van-cell {
    // height: 56px;
    // line-height: 44px;
    text-align: center;
  }
  /deep/.van-tabs__line {
    background-color: #07c160;
  }
  .img_info {
    height: 100%;
    width: 100%;
    border-radius: 14px;
  }
  .paySuccess {
    display: flex;
    // margin-top: 15px;
    margin: 80px auto;
    align-items: center;
    justify-content: center;
    .icon_success {
      height: 53px;
      width: 53px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .success_tittle {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      .tj {
        width: 68px;
        height: 24px;
        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 24px;
      }
      .what {
        width: 126px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #cccccc;
        line-height: 20px;
      }
    }
  }
  /deep/.van-cell__title {
    display: flex;
  }
  .kefu {
    color: #999;
    text-align: center;
    margin-bottom: 30px;
  }
  .tuijian {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
  }
}
.tips{
  color: red;
  font-size: 12px;
  line-height: 20px;
  margin: 20px;
}
.btn_recharge{
  width: 90%;
  margin: 100px auto;
  color: #fff;
  font-size: 16px;
  text-align: center;
  background: #f80;
  height: 40px;
  line-height: 40px;
}
</style>
